var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"8FX2TZ41SYG3JomaXH54i"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  ignoreTransactions: ['/api/health-check'],
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    'Script error.',
    // Avast extension error
    '_avast_submit',
    'ChunkLoadError',
    'QuotaExceededError',
    /Loading CSS chunk [\d]+ failed/i,
    // Network errors such as going offline or being blocked by a proxy
    'Failed to fetch',
    'We were unable to calculate PCP price for this vehicle',
  ],
  denyUrls: [
    // Google Adsense
    /pagead\/js/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    /clickcease\.com/i,
    /static\.zdassets\.com/i,
    /analytics\.tiktok\.com/i,
    /static\.trackedweb\.net/i,
    // Ignore Google flakiness
    /\/(gtm|ga|analytics)\.js/i,
    /www\.googletagmanager\.com/i,
  ],
  beforeSend(event, hint) {
    const error = hint.originalException

    // TODO re-visit the gtm error handling
    // https://medium.com/@rodolfo.3/sentry-for-react-gtm-and-express-41c869021990

    if (error?.message && error.message?.match(/Loading CSS chunk [\d]+ failed/i)) {
      return null
    }

    if (
      (error?.message && error.message?.match(/Load failed/i)) ||
      (error?.params && error.params?.message && error.params.message?.match(/Load failed/i))
    ) {
      return null
    }

    if (error?.exception && error.exception?.message?.match(/Failed to fetch/i)) {
      return null
    }

    if (error?.exception?.status === 404 || error?.status === 404) {
      return null
    }

    if (error?.exception?.status === 500 || error?.status === 500) {
      return null
    }

    return event
  },
})
